// Avoid `console` errors in browsers that lack a console.

(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());



//Functionality Fixes for Turbolinks
//If some javascript loads multiple times, it needs to be wrapped in 'turbolinks:load' function below. setInterval is a known issue, and so it needs to have clearTimeout on every turbolinks load 

function setIntervalWithTurbolinks(intervalFunction, milliseconds) {
  var interval = setInterval(intervalFunction, milliseconds);

  $(document).on('turbolinks:before-cache turbolinks:before-render', function() {
    clearTimeout(interval);
  });
}


  //JQUERY IS NOW WITHIN TURBOLINKS CLOSURE 
    $(document).on('turbolinks:load', function() {
        //MATERIALIZE SCRIPTS
     //   M.AutoInit();
      (function($){//Closure for Jquery Namespace

        $(function() {//on document ready

          //materialize sidenav
          $('.sidenav').sidenav();
          //materialize sticky mobile menu
          $('#navigationcontainer').pushpin({
            top: 64
          });

          $('.carousel.carousel-slider').carousel({fullWidth: true, autoplay: true});

          setIntervalWithTurbolinks(function() {
            $('.carousel.carousel-slider').carousel('next');
          }, 5000);
      
          // Active Main Navigation Link, ignore homepage
          if (location.pathname != "/") {
            $('.SiteNavUL li a[href="' + location.pathname + '"]').addClass('active');
          }

        });
      })(jQuery);

    })

